import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import searchIcon from '../img/search.svg'
import '../scss/searchInput.scss'

export default function SearchInput(props) {

    const { t } = useTranslation();

    const history = useHistory();

    const [searchQuery, setSearchQuery] = useState(props.value || '');

    function handleSearch(event) {
        event.preventDefault();
        history.push(`/search?q=${searchQuery}`)
    }

    return (
        <form className="search-input" onSubmit={handleSearch} >
            <input
                type="search"
                className="search-input-element"
                placeholder={t('home.search.placeholder')}
                onChange={event => setSearchQuery(event.target.value)}
                value={searchQuery}
            />
            <button className="search-input-button" type="submit" >
                <img src={searchIcon} alt="Search" className="img-fluid" />
            </button>
        </form>
    )

}