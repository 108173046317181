import { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TagManager from 'react-gtm-module';
import config from "./config";
import Header from './component/Header';
import Footer from './component/Footer';
import Home from './component/Home';
import Element from './component/Element';
import Search from './component/Search';
import './scss/main.scss';
import { init } from './i18n';

if(config.gtm) TagManager.initialize({gtmId: config.gtm})

init();

const Loader = () => (
  <div>loading...</div>
);

function App() {

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <div>
          <Header />
          <Switch>
            <Route path="/search" component={Search} />
            <Route path="/:category/:element" component={Element} />
            <Route path="/" component={Home} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </Suspense>
  );
}

export default App;
