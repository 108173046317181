import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import categories from '../data/categories.json'
import '../scss/element.scss'

export default function Element(props) {

    const { t } = useTranslation();

    const category = props.match.params.category;
    const element = props.match.params.element;

    const fileCategory = categories.find(fileCategory => fileCategory.name === category) || { elements: [] };
    if (!fileCategory.elements.find(fileElement => fileElement === element)) props.history.push('/')


    return (
        <>
            <Helmet>
                <title>{t(`element.meta.title.${category}.${element}`)}</title>
                <meta name="description" content={t(`element.meta.description.${category}.${element}`)} />
            </Helmet>
            <div className="element" >
                <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: t(`element.${category}.${element}`) }} />
                    <Link to="/" className="element-back" >{t('element.back')}</Link>
                </div>
            </div>
        </>
    )

}