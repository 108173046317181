import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import categories from '../data/categories.json'
import SearchInput from './SearchInput';
import '../scss/home.scss'

export default function Home(props) {

    const { t } = useTranslation();

    const elements = categories.map(category => {
        const links = category.elements.map(element => (
            <Link to={`/${category.name}/${element}`} key={`${category}-${element}`} >
                {t(`home.category.${category.name}.${element}`)}
            </Link>))
        return (
            <div className="col-12 col-lg-3 col-md-4 col-sm-6 pb-5" key={category.name} >
                <h2>{t(`home.category.${category.name}.title`)}</h2>
                {links}
            </div>
        );
    })

    return (
        <>
            <Helmet>
                <title>{t('home.meta.title')}</title>
                <meta name="description" content={t('home.meta.description')} />
            </Helmet>
            <div className="home" >
                <div className="container" >
                    <h1 className="home-title" >{t('home.title')}</h1>
                    <div className="row" >
                        <div className="col-12 col-lg-6 offset-lg-3" >
                            <SearchInput />
                        </div>
                    </div>
                    <div className="row home-elements" >{elements}</div>
                </div>
            </div>
        </>
    )

}