import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import queryString from "query-string";
import SearchInput from './SearchInput';
import categories from '../data/categories.json'
import '../scss/search.scss'

export default function Search(props) {

    const { t } = useTranslation();

    const query = queryString.parse(props.location.search).q;

    let elements = categories.reduce((elements, category) => {
        category.elements.forEach(element => {
            elements.push({
                category: category.name,
                name: element,
                content: t(`element.${category.name}.${element}`),
                score: 0
            })
        })

        return elements;
    }, [])

    elements.forEach(element => {
        if (element.content.indexOf(query) !== -1) element.score += 10
        query.split(' ').forEach(queryElement => {
            if (element.content.indexOf(queryElement) !== -1) element.score++
        })
    })

    elements = elements
        .filter(element => element.score)
        .sort((elementA, elementB) => elementB.score - elementA.score);

    function renderResults() {
        if (elements.length === 0) return (<p>{t('search.empty')}</p>)

        return elements.map(element => {
            return (
                <Link
                    className="search-element"
                    key={`${element.category}-${element.name}`}
                    to={`/${element.category}/${element.name}`}
                >
                    <div className="search-element-title" >{t(`search.${element.category}.${element.name}.title`)}</div>
                    <div className="search-element-content" >{t(`search.${element.category}.${element.name}.content`)}</div>
                </Link>
            );
        })
    }

    return (
        <>
            <Helmet>
                <title>{t(`search.meta.title`)}</title>
                <meta name="description" content={t(`search.meta.description`)} />
            </Helmet>
            <div className="search" >
                <div className="container my-4" >
                    <div className="row mb-4" >
                        <div className="col-12 col-lg-6 offset-lg-3" >
                            <SearchInput value={query} />
                        </div>
                    </div>
                    {renderResults()}
                    <Link to="/" className="search-back" >{t('search.back')}</Link>
                </div>
            </div>
        </>
    )

}