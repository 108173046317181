const config = {
    competitiveApi: {
        host: 'http://localhost:3001',
        websiteName: 'app-mail-blog'
    },
    mainWebsite: 'https://lettre24.com',
    tradEnv: 'staging'
}

if(['app-mail-blog.bizzw.com'].indexOf(window.location.hostname) !== -1) {
    config.competitiveApi.host = 'https://api-competitive-staging.bizzw.com';
}

if(['faq.lettre24.com'].indexOf(window.location.hostname) !== -1) {
    config.competitiveApi.host = 'https://api-competitive.bizzw.com';
    config.competitiveApi.websiteName = 'faq.lettre24.com';
    config.gtm = 'GTM-WS535L5';
    config.tradEnv = 'live';
}

export default config;