import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import config from './config';

export function init() {
    i18n.use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: { escapeValue: false },
        backend: {
            loadPath: `https://service-generator.s3.amazonaws.com/${config.tradEnv}/website/${config.competitiveApi.websiteName}.json`,
            parse: (data) => {
                const deepContent = JSON.parse(data).content.reduce((content, element) => {
                    const keyArray = element.key.split('.');
                    keyArray.reduce((content, value, index) => {
                        if (!content) content = {};
                        if (typeof content !== 'object') return content;
                        if (index + 1 !== keyArray.length && !content[value]) content[value] = {};
                        if (index + 1 === keyArray.length) content[value] = element.value;
                        return content[value]
                    }, content)
                    return content;
                }, {})
                return deepContent
            }
        }
    });
}